<template>
  <b-button
    :disabled="disabled"
    type="button"
    @click="$emit('click')"
    style="background-color: #198ae3; border-color: #198ae3"
    class="btn h1 m-0 text-white align-items-center"
    ><i class="mdi mdi-plus"></i> {{ text }}
  </b-button>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
