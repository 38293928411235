<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto"
        >Liste des Attributs
      </span>

      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <CustomAddButton
            text="Nouvel attribut"
            :disabled="disabled || !(isAdmin || isSuper || isArchitect)"
            @click="addItem"
          />
        </div>
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          responsive
          :items="items"
          :fields="fields"
          show-empty
          bordered
          striped
        >
          <!-- FOR LOADING ANIMATIONS -->
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2"></b-spinner>
              <strong>Chargement...</strong>
            </div>
          </template>
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template v-slot:cell(dcp)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <b-badge v-if="data.value == 'Oui'" variant="danger">{{
                data.value
              }}</b-badge>
              <b-badge v-else variant="success"> {{ data.value }}</b-badge>
            </span>
            <div class="row m-0" v-else>
              <b-form-select
                v-model="data.item.dcp"
                :options="['Oui', 'Non']"
              />
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.items.$each[data.index].dcp.$dirty"
              >
                {{
                  !$v.items.$each[data.index].dcp.required
                    ? "champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </div>
          </template>
          <template v-slot:cell(attribut)="data">
            <span
              v-if="!data.item.hasOwnProperty('editMode')"
              class="text-capitalize"
            >
              {{ data.value }}
            </span>
            <b-form-input
              :class="{
                'is-invalid':
                  $v.items.$each[data.index].attribut.$error &&
                  $v.items.$each[data.index].attribut.$dirty,
              }"
              v-model="data.item.attribut"
              v-else
              placeholder="Attributs"
              class="text-capitalize"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.items.$each[data.index].attribut.$dirty"
            >
              {{
                !$v.items.$each[data.index].attribut.required
                  ? "champ obligatoire"
                  : ""
              }}
            </span>
            <!--  -->
          </template>
          <template v-slot:cell(sensible)="data">
            <span v-if="!data.item.hasOwnProperty('editMode')">
              <b-badge v-if="data.value === 0" variant="success">{{
                data.value
              }}</b-badge>
              <b-badge v-else variant="danger">{{ data.value }}</b-badge>
            </span>
            <div class="row m-0" v-else>
              <b-form-select
                v-model="data.item.sensible"
                :options="[0, 1, 2, 3]"
              />
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.items.$each[data.index].sensible.$dirty"
              >
                {{
                  !$v.items.$each[data.index].sensible.required
                    ? "champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
            </div>
          </template>
          <template #cell(actions)="data">
            <table-actions
              :actions="
                isAdmin || isSuper || isArchitect ? [('edit', 'delete')] : []
              "
              :editMode="data.item.hasOwnProperty('editMode')"
              @editItem="editItem(data.index)"
              @deleteItem="deleteItem(data.item.id)"
              @confirmItem="confirmItem(data.item)"
              @cancelItem="cancelItem(data.item)"
            />
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>
<script>
import TableActions from "@/components/custom/TableActions.vue";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators"; //validation
import CustomAddButton from "@/components/custom/CustomAddButton";
import { OBJET_URL } from "@/helpers/constURL";
import { mapGetters } from "vuex";
export default {
  components: {
    TableActions,
    CustomAddButton,
  },
  props: {
    attributs: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    fields: [
      { key: "attribut", label: "Attributes", sortable: true },
      { key: "dcp", label: "DCP", sortable: true },
      { key: "sensible", label: "Sensible", sortable: true },
      {
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      },
    ],
    items: [],
  }),
  validations: {
    items: {
      $each: {
        attribut: {
          required,
        },
        dcp: {
          required,
        },
        sensible: {
          required,
        },
      },
    },
  },
  methods: {
    addItem: function () {
      if (!this.items.some((item) => item.editMode))
        this.items.unshift({ editMode: true, dcp: "Non", sensible: 0 });
    },
    deleteItem: function (id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("attribut/deleteAttribut", id).then(() => {
            this.$store
              .dispatch("objet/fetchObjet", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    editItem(index) {
      this.$store
        .dispatch("objet/fetchObjet", this.$route.params.id)
        .then(() => {
          this.items = this.items.map((attribut, i) => {
            if (index === i) {
              true;
            }
            return index === i
              ? {
                  ...attribut,
                  editMode: true,
                }
              : attribut;
          });
        });
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        // delete item.editMode;

        if (!item.id)
          this.$store
            .dispatch("attribut/createAttribut", {
              attribut:
                item.attribut.toLowerCase().charAt(0).toUpperCase() +
                item.attribut.toLowerCase().slice(1),
              dcp: item.dcp,
              sensible: item.sensible,
              objet: `/api/${OBJET_URL}/${this.$route.params.id}`,
            })
            .then(() => {
              this.$store
                .dispatch("objet/fetchObjet", this.$route.params.id)
                .then(() => {
                  Swal.fire({
                    title: "L'attribut est bien créé !",
                    type: "success",
                    confirmButtonText: "OK",
                  });
                });
            });
        else
          this.$store
            .dispatch("attribut/updateAttribut", {
              id: item.id,
              attribut:
                item.attribut.toLowerCase().charAt(0).toUpperCase() +
                item.attribut.toLowerCase().slice(1),
              dcp: item.dcp,
              sensible: item.sensible,
              objet: `/api/${OBJET_URL}/${this.$route.params.id}`,
            })
            .then(() => {
              this.$store
                .dispatch("objet/fetchObjet", this.$route.params.id)
                .then(() => {
                  Swal.fire("L'attribut est mis à jour !", "", "success");
                });
            });
      }
    },
    cancelItem(item) {
      if (item.attribut === "" && item.dcp === "Non" && item.sensible === 0)
        this.$store.dispatch("objet/fetchObjet", this.$route.params.id);
      else (item.attribut = ""), (item.dcp = "Non"), (item.sensible = 0);
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isArchitect"]),
  },
  mounted() {
    this.items = this.attributs;
  },
  watch: {
    attributs() {
      this.items = this.attributs;
    },
  },
};
</script>
